import React, { Component } from 'react';

import firebase from '@firebase/app';
import '@firebase/firestore';
import isDarkColor from 'is-dark-color';
import favicolor from 'favicolor';

import Moment from 'react-moment';
import 'moment-timezone';

import grid from './img/grid.png';
import lines from './img/lines.png';

import './App.scss';

const config = {
  apiKey: "AIzaSyChtrTsqxIfqCWvogzHKU0rTZ27td-fcd0",
  authDomain: "nskyc.com",
  projectId: "nskyc-3727d"
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      grid: false
    }

    this.db = null;
    this.icon = null;
  }

  componentDidMount() {
    firebase.initializeApp(config);
    this.db = firebase.firestore();
    this.icon = document.querySelector('[rel=icon]');

    this.db.collection("nyc")
      .orderBy("created", "desc")
      .limit(290)
      .onSnapshot((querySnapshot) => {
        let data = [];
        let c = 0;
        querySnapshot.forEach((doc) => {
          let d = doc.data();

          if(c === 0) {
            favicolor(this.icon, d.color);
          }

          d.id = doc.id;
          data.push(d);
          c++;
        });

        this.setState({data});
    });
  }

  render() {
    return (
      <div className="app">

        <div className="toolbar">
          <div className="logo">
            <span>N</span>
            <span>S</span>
            <span>K</span>
            <span>Y</span>
            <span>C</span>
          </div>
          <div className="toggle">
            <button onClick={()=>this.setState({grid: false})} style={this.state.grid ? { opacity: 0.4} : {opacity: 1}}>
              <img src={grid} alt="Grid Mode" title="Grid Mode" />
            </button>
            <button onClick={()=>this.setState({grid: true})} style={!this.state.grid ? { opacity: 0.4} : {opacity: 1}}>
              <img src={lines} alt="Line Mode" title="Line Mode" />
            </button>
          </div>
        </div>

        <div className={this.state.grid ? "colors colors-line" : "colors colors-grid"}>
        {
          this.state.data.map((color, c) => {
            const d = new Date(color.created.seconds * 1000);
            return <div key={color.id} className={isDarkColor(color.color) ? "color color-dark" : "color"} style={{backgroundColor: color.color }}>
              <div className="color-hex"><span style={{backgroundColor: color.color }}>{color.color}</span></div>
              <div className="color-time"><Moment date={d} interval={0} fromNow /></div>
              <div className="color-image" style={{backgroundImage: 'url('+color.fileName+')'}}></div>
            </div>;
          })
        }
        </div>

        <div className="bodge"><a href="https://bod.ge" target="_blank" rel="noopener noreferrer">bodge</a></div>
      </div>
    );
  }

  hover(file) {
    console.log(file);
  }
}

export default App;
